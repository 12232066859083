import { useDashboard } from '@capturi/dashboard'
import React from 'react'
import { useParams } from 'react-router-dom'

const DashboardAdminPage = React.lazy(() => import('./DashboardAdminPage'))
const DashboardViewPage = React.lazy(() => import('./DashboardViewPage'))

export default function DashboardPage(): React.ReactElement | null {
  const params = useParams()
  const { dashboard } = useDashboard({
    uid: params.uid,
  })

  if (params.uid == null || dashboard?.accessLevel === 'None') {
    return null
  }

  return dashboard?.accessLevel === 'Edit' ? (
    <DashboardAdminPage />
  ) : (
    <DashboardViewPage />
  )
}
